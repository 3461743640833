<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/pass.png" class="icon" />
            <input type="number" oninput="if(value.length > 4)value = value.slice(0, 4)" v-model="password" class="input" placeholder="请输入4位数字" />
          </div>
        </div>
        <div class="button primary mt" @click="submit">提交</div>
        <div class="button1 primary mt" @click="goback">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    this.client_id = this.$route.query.client_id;
    this.stie_code = this.$route.query.stie_code;
    this.name = this.$route.query.name;
    this.tel = this.$route.query.tel;
    this.unionid = this.$route.query.unionid;
  },
  data() {
    return {
      stie_code: '',
      client_id: '',
      name: '',
      tel: '',
      unionid: '',
      state:false,
      password: ''
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/medical/scan_code', query:{
        name:this.name,
        tel:this.tel,
        unionid:this.unionid
      }});
    },
    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      //Dialog({ message: 'pw:'+ this.password+',stie_code:'+ this.stie_code+',client_id:'+ this.client_id});
      if(isEmpty(this.password)) {
        Dialog({ message: '请输入密码' });
        this.state = false;
      }else{
        let { data } = await this.axios.post(
          '/medicines/yd_tlService/jy_bdby',
          qs.stringify({
            pw: this.password,
            stie_code: this.stie_code,
            client_id: this.client_id
          })
        );
        if (data.flag === '0') {
          this.state = false;
          Toast(data.message);
          this.$router.replace('/refueling/my');          
        } else {
          this.state = false;
          Toast(data.message);
        }
            
      }
     
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 229px;
}
.content {
  margin-top: 60px;
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}
</style>